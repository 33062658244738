.custom-steps .ant-steps-item-finish .ant-steps-icon {
  background-color: #42bd41; 
  border-radius: 50%; /* ทำให้เป็นวงกลม */
  width: 32px; /* ขนาดของวงกลม */
  height: 32px; /* ขนาดของวงกลม */
  display: flex; /* ให้สามารถใช้ flex เพื่อจัดตำแหน่งภายใน */
  align-items: center; /* จัดกลางแนวตั้ง */
  justify-content: center; /* จัดกลางแนวนอน */
  border: none; /* ลบขอบถ้ามี */
  box-shadow: none; /* ลบเงาถ้ามี */
}

.custom-steps .ant-steps-item-wait .ant-steps-icon {
  background-color : gold; 
  border-radius: 50%; /* ทำให้เป็นวงกลม */
  width: 32px; /* ขนาดของวงกลม */
  height: 32px; /* ขนาดของวงกลม */
  display: flex; 
  /* ให้สามารถใช้ flex เพื่อจัดตำแหน่งภายใน */
  align-items: center; 
  /* จัดกลางแนวตั้ง */
  justify-content: center; 
  /* จัดกลางแนวนอน */
  /* font-size: 16px; */
}










