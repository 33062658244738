@media print {
    @page {
        size: A4;
        margin: 0;
    }
}

@media print {
    .print-content {
        width: 210mm;
        /* ขนาดกว้างของกระดาษ A4 */
        height: 297mm;
        /* ขนาดสูงของกระดาษ A4 */
    }
}