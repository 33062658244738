/* responsiveTable.css */
@media screen and (max-width: 600px) {
  /* ตั้งค่าให้ตารางไม่มีเส้นขอบ */
  table {
    border: 0;
  }
  /* ตั้งค่าขนาดตัวอักษรของ caption ในตารางเป็น 1.3em */
  table caption {
    font-size: 1.3em;
  }
  /* ซ่อนส่วนหัวของตาราง โดยตั้งค่าให้ border เป็น none, ใช้ clip เพื่อตัดส่วนที่มองไม่เห็น, ตั้งค่าขนาดให้เล็กมาก ๆ และใช้ตำแหน่งแบบ absolute */
    .responsive-table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    /* ตั้งค่าให้แต่ละแถวของตารางมีเส้นขอบล่างขนาด 3px สี #ddd, แสดงผลเป็นบล็อก, และมีระยะห่างด้านล่าง .625em */
    .responsive-table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    /* ตั้งค่าให้แต่ละเซลล์ของตารางมีเส้นขอบล่างขนาด 1px สี #ddd, แสดงผลเป็นบล็อก, ขนาดตัวอักษร .8em, และจัดข้อความให้อยู่ขวา */
    .responsive-table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    /* ตั้งค่าเนื้อหาที่จะใส่ก่อนเซลล์ของตาราง โดยใช้ค่า data-label ที่กำหนดใน HTML, จัดตำแหน่ง float ให้ซ้าย, ตั้งค่า font-weight เป็น bold, และตัวอักษรเป็นตัวพิมพ์ใหญ่ทั้งหมด */
    .responsive-table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    /* ตั้งค่าให้เซลล์สุดท้ายในแต่ละแถวไม่มีเส้นขอบล่าง */
    table td:last-child {
      border-bottom: 0;
    }
  }

  .input-button-group {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 8px; /* Adjust spacing between Input and Button */
  }
  