/* ใช้หน้า Offcase  */
/* p {
  text-align: center;
}
span {
  text-align: left;
}
.row {
  margin: 3;
} */
/* mobile landscape screen */
@media screen and (min-width: 375px) {
  .main {
      & aside {
          display: grid;
          margin: 0;
          grid-template-columns: 1fr;

          & div {
              margin: 0;
          }
      }
  }
  .main2 {
      align-items: center;
      width: '90%';

      & aside {
          display: grid;
          grid-template-columns: 1fr;
      }


  }
}
/* mobile landscape screen */
@media screen and (min-width: 680px) {
  .main {
      & aside {
          display: grid;
          grid-template-columns: 1fr 1fr;

          & div {
              margin: 0;
          }
      }
  }
}
/* mobile screen */
@media screen and (min-width: 1200px) {
  .main {
      & aside {
          display: grid;
          /* grid-template-columns: repeat(3, 1fr); */
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas:
              "a a a"
              "a a a";

          & div {
              margin: 0;
              width: 100%;
          }
      }
  }
}