.myButtonGreen {

	box-shadow: 1px 7px 24px -7px #62d660;

	background:linear-gradient(to bottom, #72f70d 5%, #76ff03 100%);

	background-color:#76ff03;

	border-radius:8px;

	display:inline-block;

	cursor:pointer;

	color:#000000;

	font-family:Arial;

	font-size:12px; 

	font-weight:bold;

	padding:10px 12px;

	text-decoration:none;

	text-shadow:0px 0px 0px #3d768a;


}
.myButtonYellow {

	box-shadow: 1px 7px 24px -7px #f6f621;

	background:linear-gradient(to bottom, #f9e638 5%, #eeee2f 100%);

	background-color:#ffeb3b;

	border-radius:8px;

	display:inline-block;

	cursor:pointer;

	color:000000;

	font-family:Arial;

	font-size:12px;

	font-weight:bold;

	padding:10px 12px;

	text-decoration:none;

	text-shadow:0px 0px 0px #3d768a;

}
.myButtonOrange {

	box-shadow: 1px 7px 24px -7px #ff9a02;

	background:linear-gradient(to bottom, #f88820 5%,  #ff8b37 100%);

	background-color:#ff7c02;

	border-radius:8px;

	display:inline-block;

	cursor:pointer;

	color:#000000;

	font-family:Arial;

	font-size:12px;

	font-weight:bold;

	padding:10px 10px;

	text-decoration:none;

	text-shadow:0px 0px 0px #3d768a;

}

.myButtonOriginal {

	/* box-shadow: 1px 7px 24px -7px #ff9a02;

	background:linear-gradient(to bottom, #f88820 5%,  #ff8b37 100%);

	background-color:#ff7c02; */

	border-radius:8px;

	display:inline-block;

	cursor:pointer;

	color:#000000;

	font-family:Arial;

	font-size:12px;

	font-weight:bold;

	padding:10px 10px;

	text-decoration:none;

	text-shadow:0px 0px 0px #3d768a;

}


.myButton:hover {

	background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);

	background-color:#408c99;

}

.myButton:active {

	position:relative;

	top:1px;

}