:root {
  --heightA4: 1123px;
}  
.divImgP11 {
  background-size: cover;
  height: var(--heightA4);   /* width: 794PX;  */
  background-image: url("../picture/P11.jpg");
  /* background: red; */
  z-index: -1;
  }
.divImgP12 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../picture/P12.jpg");
  z-index: -1;
  }  

.divImgP21 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../picture/P21.jpg");
  }

.divImgP22 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../picture/P22.jpg");
  }  

.divImgP23 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../picture/P23.jpg");
  }  
  
.divImgP24 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../picture/P24.jpg");
  }
  
.divImgP25 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../picture/P25.jpg");
  } 

  .divImgP0 {
    background-size: cover;
    height: var(--heightA4);
    background-image: url("../picture/P0.jpg");
    } 

  .container {
    height: 1123PX;
    width: 794PX;
    padding: 10px;
    position: relative;
    z-index: 1;
    /* opacity: 1; */
  }  

  .divTextWatermark {
    /* height: "1123PX"; */
    /* width: "794PX"; */
    position: absolute;
    z-index: 2;
    font-size: 30px;  /* ขนาดฟอนต์ของลายน้ำ */
    transform: rotate(-55deg);  /* หมุนตัวอักษรไป 45 องศา */
    bottom: 150px;  
    /* ปรับตำแหน่งตามที่ต้องการ */
    left: 120px;  
    /* ปรับตำแหน่งตามที่ต้องการ */
    transform-origin: left bottom;  /* กำหนดจุดหมุนที่มุมซ้ายล่าง */
    white-space: nowrap;  /* ป้องกันตัวอักษรตัดบรรทัด */
    color: #000;
  } 


