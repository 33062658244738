#icon {
    vertical-align: middle;
    line-height: 50px;
    font-size: 30px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
}



.navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 8px 8px 8px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #f5f5f5;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #060b26;
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: start;
    align-items: center;
}

.sidenav {
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    /* overflow-x: hidden; */
    /* max-height: calc(100vh - 9rem); */
    max-height: 100%;
    overflow-y: auto;
    transition: 0.5s;
}

.sidenav a {
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    /* font-size: 25px; */
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    /* font-size: 36px; */
    /* margin-left: 0px; */
}

.main5 {
    display: flex;
    /* transition: 0.5s; */
    justify-content: space-between;
    /* grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr; */
    width: 100%;
    align-items: center;
    height: 84px;
}

.start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 84px;
}

.end-new {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 84px;
    margin-right: 10px;
}

#main {
    top: 0;
    right: 0;
    position: sticky;
    /* z-index: 1000; */
    transition: margin-left 0.5s;
    /* padding: 16px; */
    width: '100%';
    /* height: 100vh; */
}



/* @media screen and (max-width: 375px) {
    .main5 {
        width: 100%;

        &aside {
            display: grid;
            grid-template-rows: 1fr 1fr;
        }
    }

    .sidenav {
        padding-top: 15px;
        font-size: 16px;
    }

    .sidenav a {
        font-size: 14px;
    }
} */

@media screen and (max-width: 375px) {
    .main5 {
        width: 100%;

        aside { /* แก้เป็น aside โดยไม่ใช้ & */
            display: grid;
            grid-template-rows: 1fr 1fr;
        }
    }

    .sidenav {
        padding-top: 15px;
        font-size: 16px;
    }

    .sidenav a {
        font-size: 14px;
    }
}



/* mobile screen */
@media screen and (max-width: 768px) {

    .main5 {
        background: #002766;
        color: white;
        width: 100%;
    }

    .sum {
        padding-top: 15px;
        flex-direction: column;

        /* .start {
            justify-content: flex-start;
        } */
    }

}

/* tablet screen */
@media screen and (min-width: 768px) {
    .main5 {
        background: #002766;
        color: white;
        width: 100%;
    }
}