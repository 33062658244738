.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 4px;
    /* border: 1px solid #40a9ff; */
}

.space-align-block .mock-block {
    display: inline-block;
    padding: 32px 8px 16px;
    background: rgba(150, 150, 150, 0.2);
}


.dotted {
    border-style: dotted;
    border-color: white white black white;
    border-width: 1.5px;
}

.pp {
    margin-top: 20px;
    margin-bottom: 10px;
}

.wi {
    width: 250px;
}

.wi1 {
    width: 80px;
}

.wi2 {
    width: 285px;
}

.wi3 {
    width: 275px;
}

.wi4 {
    width: 235px;
}