:root {
  --marginTopBottom: 2px;     /* var(--aa);  */
  --fontSize: 16px;
  --fontFamily: Angsana New;
  --color: black;
}

.Col24 {
    margin-top: var(--marginTopBottom); 
    margin-bottom: var(--marginTopBottom);
    font-size: 24px; 
    font-family: var(--fontFamily); 
    color : var(--color); 
}

.Colbold24C {
    margin-top: var(--marginTopBottom); 
    margin-bottom: var(--marginTopBottom);
    font-size: 24px;
    font-weight: bold; 
    font-family: var(--fontFamily);
    text-align: center; 
    color : var(--color); 
}
.Colbold24L {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: 24px;
  font-weight: bold; 
  font-family: var(--fontFamily); 
  color : var(--color); 
}

.Col22 {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: 22px; 
  font-family: var(--fontFamily); 
  color : var(--color); 
}

.div22 {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: 22px; 
  font-family: var(--fontFamily); 
   width: "560px";
   color : var(--color); 
}

.Col22ZZ {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: 22px; 
  font-family: var(--fontFamily); 
  color : var(--color); 
}

.Col22C {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: 22px; 
  font-family: var(--fontFamily); 
  text-align: center;
  color : var(--color);  
}

.Colbold22 {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: 22px;
  font-weight: bold; 
  font-family: var(--fontFamily);
  text-align: center; 
  color : var(--color); 
}

.ColUnderline {
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom); 
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  border-bottom: 1px dotted;
  /* height: 20px;  */
  text-align: center;
  color : var(--color); 
  }


 


