.button-save{
    color: white;
    background-color: green;
    justify-content: end;
    padding: 10px;
    margin: 10px;
}

.button-cancel{
    color: white;
    background-color: orange;
    justify-content: end;
    padding: 10px;
    margin: 10px;
}

.button-reset{
    color: white;
    background-color: gray;
}

.input {
    width: 100%;
    height: 40px;
}

.button-submit{
    color: white;
    background-color: green;
    justify-content: end;
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.button-cancel1{
    color: white;
    background-color: orange;
    justify-content: end;
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.blinking {
    animation: blink-animation 1s infinite;
  }
  
  @keyframes blink-animation {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }


