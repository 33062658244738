:root {
  --heightA4: 1123px;
}  
/* CSS to hide element in the screen but show in print */
@media screen {
  .print-only {
    display: none;
  }
}
@media print {
  .print-only {
    display: block;
  }
}
.divImgP11 {
  background-size: cover;
  height: var(--heightA4);   /* width: 794PX;  */
  background-image: url("../../../check_info_ad_formland/modal/picture/P11.jpg");
  /* background: red;  */
  }
.divImgP12 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../../../check_info_ad_formland/modal/picture/P12.jpg");
  }  

.divImgP21 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../../../check_info_ad_formland/modal/picture/P21.jpg");
  }

.divImgP22 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../../../check_info_ad_formland/modal/picture/P22.jpg");
  }  

.divImgP23 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../../../check_info_ad_formland/modal/picture/P23.jpg");
  }  
  
.divImgP24 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../../../check_info_ad_formland/modal/picture/P24.jpg");
  }
  
.divImgP25 {
  background-size: cover;
  height: var(--heightA4);
  background-image: url("../../../check_info_ad_formland/modal/picture/P25.jpg");
  }

  .divImgP0 {
    background-size: cover;
    height: var(--heightA4);
    background-image: url("../../../check_info_ad_formland/modal/picture/P0.jpg");
    } 

  .container {
    height: 1123PX;
    width: 794PX;
    position: relative;
    z-index: 2;
    /* opacity: 1; */
  }  

  .divTextWatermark {
    position: absolute;
    z-index: 3;
    font-size: 24px;  /* ขนาดฟอนต์ของลายน้ำ */
    transform: rotate(-55deg);  /* หมุนตัวอักษรไป 45 องศา */
    bottom: 240px;  
    /* ปรับตำแหน่งตามที่ต้องการ */
    left: 100px;  
    /* ปรับตำแหน่งตามที่ต้องการ */
    transform-origin: left bottom;  /* กำหนดจุดหมุนที่มุมซ้ายล่าง */
    white-space: nowrap;  /* ป้องกันตัวอักษรตัดบรรทัด */
    color: #000;
  } 
  .divTextWatermark2 {
    position: absolute;
    z-index: 3;
    font-size: 24px;  /* ขนาดฟอนต์ของลายน้ำ */
    transform: rotate(-55deg);  /* หมุนตัวอักษรไป 45 องศา */
    bottom: 120px;  
    /* ปรับตำแหน่งตามที่ต้องการ */
    left: 100px;  
    /* ปรับตำแหน่งตามที่ต้องการ */
    transform-origin: left bottom;  /* กำหนดจุดหมุนที่มุมซ้ายล่าง */
    white-space: nowrap;  /* ป้องกันตัวอักษรตัดบรรทัด */
    color: #000;
  } 


