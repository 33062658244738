@media print {
  .divA4{
    width: 210mm;
    /* height: 297mm; */
    height: 100mm;
    box-sizing: border-box;
    border: none; /* ไม่ต้องการเส้นขอบตอนพิมพ์ */
    page-break-after: always; /* บังคับการแบ่งหน้าหลัง divA4 */
    padding: 16px; /* กำหนด 4 ขอบกระดาษ ขอบกระดาษจริง == 96px  18px*/
  }

  .divA4Cut{
    width: 223mm;
    /* height: 297mm; */
    height: 140mm;
    box-sizing: border-box;
    border: none; /* ไม่ต้องการเส้นขอบตอนพิมพ์ */
    page-break-after: always; /* บังคับการแบ่งหน้าหลัง divA4 */
    padding: 24px; /* กำหนด 4 ขอบกระดาษ ขอบกระดาษจริง == 96px*/
  }

  .divEdge80{
    padding: 80px; /* กำหนด 4 ขอบกระดาษ Auto */
  }
  
  /* ขนาดตัวหนังสือของหัวตาราง */
  .tabelA1 .ant-table-thead > tr > th {
    font-size: 16px;  /* 12px */
    background-color: #ffecb3;
    /* font-weight: bold; */
    font-family: "Angsana New";  
  }
  /* ขนาดตัวหนังสือของข้อมูลในแต่ละ row */
  .tabelA1 .ant-table-tbody > tr > td {
    font-size: 16px; 
    border: none; /* ไม่ต้องการเส้นขอบตอนพิมพ์ */
    padding-bottom: 0px; /* ระยะห่างภายในแต่ละเซลล์ */
  }

.colLeft {
  margin-top: 1px; 
  margin-bottom: 1px;
  font-size: 18px;  /* 18px  */
  font-family: "Angsana New";  
}

.colLeft12 {
  margin-top: 1px; 
  margin-bottom: 1px;
  font-size: 13px; 
  font-family: "Angsana New";
  color: #000; 
}

.colCenter {
  margin-top: 1px; 
  margin-bottom: 1px;
  font-size: 18px; 
  font-family: "Angsana New"; 
  text-align: center;
  }

.colRight {
  margin-top: 1px; 
  margin-bottom: 1px;
  font-size: 18px; 
  font-family: "Angsana New"; 
  text-align: right;
  }

.colUnderline {
  margin-top: 5px; 
  margin-bottom: 5px; 
  border-bottom: 1px dotted;
}

.divQr {
  position: absolute; 
  z-index: -1;  
  border: 1px solid black;
  padding: 5px;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.box {
  display: inline-block;
  /* padding-right: 5px; */
  width: 12px;
  height: 12px;
  border: 1px solid black;
  margin-right: 5px;
  text-align: 'center';
  line-height: 5px ;
  /* border-radius: 4px;  */
  /* เพิ่มมุมโค้งถ้าต้องการ */
}

.picA {
  display: inline-block;
  /* padding-right: 5px; */
  width: 12px;
  height: 12px;
  /* border: 1px solid black; */
  margin-right: 5px;
}

}