:root {
  /* --marginTopBottom: 1px;     var(--aa);  */
  --fontSize: 17px;
  --fontFamily: Angsana New;
  --color: black;
}

.Col {
  /* margin-top: var(--marginTopBottom);  */
  /* margin-bottom: var(--marginTopBottom); */
  font-size:  18px; 
  font-family: var(--fontFamily);
  color : var(--color);  
  }

.Colbold18 {
    /* margin-top: var(--marginTopBottom);  */
    /* margin-bottom: var(--marginTopBottom); */
    font-size: 18px;
    font-weight: bold; 
    font-family: var(--fontFamily);
    color : var(--color);  
  }

.ColCenter {
  /* margin-top: var(--marginTopBottom);  */
  /* margin-bottom: var(--marginTopBottom); */
  font-size:  18px; 
  font-family: var(--fontFamily); 
  text-align: center;
  color : var(--color); 
  }

.ColRight {
  /* margin-top: var(--marginTopBottom);  */
  /* margin-bottom: var(--marginTopBottom); */
  font-size:  18px; 
  font-family: var(--fontFamily); 
  text-align: right;
  color : var(--color); 
  }

.ColUnderline {
  /* margin-top: var(--marginTopBottom);  */
  /* margin-bottom: var(--marginTopBottom);  */
  font-size:  18px; 
  font-family: var(--fontFamily); 
  border-bottom: 2px dotted;
  /* height: 20px;  */  
  text-align: center;
  color : var(--color); 
  }
.ColUnderlineLeft {
    /* margin-top: var(--marginTopBottom);  */
    /* margin-bottom: var(--marginTopBottom);  */
    font-size: 16px; 
    font-family: var(--fontFamily); 
    border-bottom: 1px dotted;
    color : var(--color); 
  }

.ColUnderline24 {
  font-family: var(--fontFamily); 
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
  color : var(--color); 
  }

.ColUnderline18 {
  /* margin-top: var(--marginTopBottom);  */
  /* margin-bottom: var(--marginTopBottom); */
  font-family: var(--fontFamily); 
  font-size: 17px;
  font-weight: bold;
  text-decoration: underline;
  color : var(--color); 
  }

.span1 {
  /* margin-top: var(--marginTopBottom);  */
  /* margin-bottom: var(--marginTopBottom); */
  font-size:  17px; 
  font-family: var(--fontFamily); 
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1;
  display: inline-block;
  width: 15px;
  height: 20px;
  background-color: white;
  border: 1px solid black;
  margin-right: 8px; 
  /* margin-top: 7px; */
  }



 


