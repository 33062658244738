:root {
  --marginTopBottom: 0px;     /* var(--aa);  */
  --fontSize: 16px;
  --fontFamily: Angsana New;
}

.divA4{
  width: 210mm;
  height: 100mm;
  /* height: 297mm; */
  border: 1px solid black; /* เพิ่มเส้นขอบเพื่อดูขนาด */
  box-sizing: border-box;
}

/* กำหนด 4 ขอบกระดาษจริง == 96px */
.divEdge30{
  /* padding: 56px; */
  padding: 56px; /* กำหนด 4 ขอบกระดาษ 30px; */
}
.divEdge96{
  padding: 88px; /* กำหนด 4 ขอบกระดาษ Auto */
}

.colLeft {
  height: 16px;     /* เพิ่ม2 */
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  text-align: left; /* เพิ่ม3 */
  color: black;
}

.colCenter {
  height: 16px;     /* เพิ่ม2 */
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  text-align: center;
  color: black;
  }

.colRight {
  height: 16px;     /* เพิ่ม2 */
  margin-top: var(--marginTopBottom); 
  margin-bottom: var(--marginTopBottom);
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  text-align: right;
  color: black;
  }

.colUnderline {
  margin-top: 5px; 
  margin-bottom: 5px; 
  border-bottom: 1px dotted;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse; /* ทำให้เส้นขอบไม่ทับกัน */
}
.rowTable {
  /* display: table-row; */
  display: flex;
}
.colTable {
  display: table-cell;
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  /* กำหนดเส้นขอบเป็นสีดำ ขนาด 1px */
  /* border: 1px solid rgba(0, 0, 0, 0.5);  */
  border: 1px solid black;
   /* จัดข้อความให้อยู่ตรงกลาง */
  text-align: center;
   /* เพิ่ม padding เพื่อให้ดูดีขึ้น */
   /* padding: 8px;  */
   flex: 1; /* ทำให้องค์ประกอบยืดความกว้างเท่ากัน */
   color: black;
}

.colTableLeft {
  display: table-cell;
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  /* กำหนดเส้นขอบเป็นสีดำ ขนาด 1px */
  /* border: 1px solid rgba(0, 0, 0, 0.5);  */
  border: 1px solid black;
   /* จัดข้อความให้อยู่ตรงกลาง */
  text-align: left;
   /* เพิ่ม padding เพื่อให้ดูดีขึ้น */
   /* padding: 8px;  */
   flex: 1; /* ทำให้องค์ประกอบยืดความกว้างเท่ากัน */
   color: black;
}

.colTableB {
  display: table-cell;
  font-size: var(--fontSize); 
  font-family: var(--fontFamily); 
  /* กำหนดเส้นขอบเป็นสีดำ ขนาด 1px */
  /* border: 1px solid rgba(0, 0, 0, 0.5);  */
  border: 1px solid black;
   /* จัดข้อความให้อยู่ตรงกลาง */
  text-align: center;
  color: black;
}

.box {
  display: inline-block;
  /* padding-right: 5px; */
  width: 12px;
  height: 12px;
  border: 1px solid black;
  margin-right: 5px;
  /* border-radius: 4px;  */
  /* เพิ่มมุมโค้งถ้าต้องการ */
}

.picA {
  display: inline-block;
  /* padding-right: 5px; */
  width: 12px;
  height: 12px;
  /* border: 1px solid black; */
  margin-right: 5px;
}









 


