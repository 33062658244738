.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* @media (min-width: 1100px) {
    body {
        max-width: 1350px !important;
    }
  } */
  .table{
    padding-top: 10px;
  
  }
  .table-body{
    width: 100%;
    /* padding-top: 50px; */

  
  } 
  .input{
    margin-bottom: 16px;
  }
  .button-save{
    color: green;
  }
  .search-input {
    width: 100%;
    max-width: 1000px; 
    border-radius: 20px; 
    padding: 8px 16px; 
  }

.button-create{
  color: white;
  background-color: #1E90FF;
  /* margin: 10px; */
}

