.center {
    margin: auto;
    width: 100%;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 10px;
    padding: 3%;
}

/* span.dotted {
    border: dotted 2px black;

    border-style: dotted;
    border-color: white white black white;
} */

h4 {
    /* border-top-style: dotted; */
    border-bottom-style: dotted;
}